export const statusOptions = {
  created: {
    text: 'Aguardando Pagamento',
    bgColor: 'rgba(var(--c-color-orange-100-rgb), 0.16)',
    textColor: 'c-text-orange-400',
    icon: 'hour-time',
    iconColor: 'var(--c-color-orange-400)',
  },
  paid: {
    text: 'Pagamento efetuado',
    bgColor: 'rgba(var(--c-color-green-100-rgb), 0.16)',
    textColor: 'c-text-green-400',
    icon: 'check',
    iconColor: 'var(--c-color-green-400)',
  },
  failed: {
    text: 'Pagamento rejeitado',
    bgColor: 'rgba(var(--c-color-orange-100-rgb), 0.16)',
    textColor: 'c-text-orange-400',
    icon: 'alert',
    iconColor: 'var(--c-color-orange-400)',
  },
  canceled: {
    text: 'Cancelado',
    bgColor: 'rgba(var(--c-color-red-400-rgb), 0.16)',
    textColor: 'c-text-red-400',
    icon: 'close',
    iconColor: 'var(--c-color-red-400)',
  },
  pending: {
    text: 'Aguardando Pagamento',
    bgColor: 'rgba(var(--c-color-orange-100-rgb), 0.16)',
    textColor: 'c-text-orange-400',
    icon: 'hour-time',
    iconColor: 'var(--c-color-orange-400)',
  },
  refunded: {
    text: 'Reembolsado',
    bgColor: 'rgba(var(--c-color-gray-200-rgb), 0.16)',
    textColor: 'c-text-gray-400',
    icon: 'forward-time',
    iconColor: 'var(--c-color-gray-400)',
  },
  partial_refunded: {
    text: 'Reembolsado parcialmente',
    bgColor: 'rgba(var(--c-color-gray-200-rgb), 0.16)',
    textColor: 'c-text-gray-400',
    icon: 'forward-time',
    iconColor: 'var(--c-color-gray-400)',
  },
  legacy_migration: {
    text: 'Migração Legado',
    bgColor: 'rgba(var(--c-color-green-100-rgb), 0.16)',
    textColor: 'c-text-green-400',
    icon: 'check',
    iconColor: 'var(--c-color-green-400)',
  },
  exchanged: {
    text: 'Trocado',
    bgColor: 'rgba(128,0,128, 0.16)',
    textColor: 'c-text-purple-400',
    icon: 'arrow-up-down',
    iconColor: 'rgb(116, 95, 207)',
  },
  default: {
    bgColor: 'rgba(var(--c-color-gray-100-rgb), 0.16)',
    textColor: 'c-text-orange-400',
    icon: 'hour-time',
    iconColor: 'var(--c-color-orange-400)',
  },
};

export const statusRecurrenceOptions = {
  created: {
    text: 'Próxima cobrança',
    bgColor: 'rgba(var(--c-color-orange-100-rgb), 0.16)',
    textColor: 'c-text-orange-400',
    icon: 'hour-time',
    iconColor: 'var(--c-color-orange-400)',
  },
  paid: {
    text: 'Pago',
    bgColor: 'rgba(var(--c-color-green-100-rgb), 0.16)',
    textColor: 'c-text-green-400',
    icon: 'check-box-circle',
    iconColor: 'var(--c-color-green-400)',
  },
  failed: {
    text: 'Pendente',
    bgColor: 'rgba(var(--c-color-red-100-rgb), 0.16)',
    textColor: 'c-text-red-400',
    icon: 'close-circle',
    iconColor: 'var(--c-color-red-400)',
  },
  canceled: {
    text: 'Cancelado',
    bgColor: 'rgba(var(--c-color-red-400-rgb), 0.16)',
    textColor: 'c-text-red-400',
    icon: 'close-circle',
    iconColor: 'var(--c-color-red-400)',
  },
  pending: {
    text: 'Aguardando',
    bgColor: 'rgba(var(--c-color-orange-100-rgb), 0.16)',
    textColor: 'c-text-orange-400',
    icon: 'hour-time',
    iconColor: 'var(--c-color-orange-400)',
  },
  refunded: {
    text: 'Reembolsado',
    bgColor: 'rgba(var(--c-color-gray-200-rgb), 0.16)',
    textColor: 'c-text-gray-400',
    icon: 'forward-time',
    iconColor: 'var(--c-color-gray-400)',
  },
  partial_refunded: {
    text: 'Reembolsado parcialmente',
    bgColor: 'rgba(var(--c-color-gray-200-rgb), 0.16)',
    textColor: 'c-text-gray-400',
    icon: 'forward-time',
    iconColor: 'var(--c-color-gray-400)',
  },
  legacy_migration: {
    text: 'Migração Legado',
    bgColor: 'rgba(var(--c-color-green-100-rgb), 0.16)',
    textColor: 'c-text-green-400',
    icon: 'check-box-circle',
    iconColor: 'var(--c-color-green-400)',
  },
  default: {
    bgColor: 'rgba(var(--c-color-gray-100-rgb), 0.16)',
    textColor: 'c-text-orange-400',
    icon: 'hour-time',
    iconColor: 'var(--c-color-orange-400)',
  },
};
